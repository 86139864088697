import React, { useRef, useState } from "react"
import useBoxSize from "../../../hooks/useBoxSize"
import styled from "styled-components"
import ExploreGallery from "./ExploreGallery"
import { GatsbyImage } from "gatsby-plugin-image"

const ExploreBox = ({
  press,
  image,
  title,
  button,
  icon,
  activityBox,
  pageUrl,
  index,
  pageClass,
  galleryImages,
}) => {
  const boxRef = useRef()
  const swidth = useBoxSize({ boxRef: boxRef })

  const [galleryOpen, setGalleryOpen] = useState(false)
  console.log("img---g", galleryImages)
  return (
    <div style={{ position: "relative" }}>
      {galleryImages && (
        <ExploreGallery
          images={galleryImages}
          open={galleryOpen}
          setOpen={setGalleryOpen}
        />
      )}

      <div
        className="explore_wrap"
        data-aos="fade-up"
        data-aos-delay={`${100 + index * 50}`}
        data-aos-duration={`${1100 + index * 50}`}
        ref={boxRef}
      >
        <a
          className="explore_image_button"
          onClick={
            pageUrl
              ? () => {}
              : () => {
                  setGalleryOpen(true)
                }
          }
          {...(pageUrl ? { href: pageUrl, target: "_blank" } : {})}
        >
          <ImageWrapper
            width={swidth}
            image={image}
            alt="explore image"
            objectFit="cover"
            objectPosition="center"
            className={`explore_thumb ${pageClass}`}
          ></ImageWrapper>
        </a>
        <div className="explore_text">
          {press ? (
            <div className="press_flx">
              {activityBox ? (
                <div className="expls_blk">
                  <a href={pageUrl} target="_blank">
                    <h4>{title}</h4>
                  </a>
                </div>
              ) : (
                <>
                  <div className="expls_blk">
                    <h4>{title}</h4>
                  </div>
                  <div className="expls_info">
                    <a
                      href={button?.url}
                      onClick={
                        button?.url ? () => {} : () => setGalleryOpen(true)
                      }
                      target="_blank"
                    >
                      {button.title} {icon}
                    </a>
                  </div>
                </>
              )}
            </div>
          ) : (
            <>
              <h4>{title}</h4>
              <a
                href={button.url}
                style={{ display: "flex", alignItems: "center" }}
              >
                {button.title} {icon}
              </a>
            </>
          )}
        </div>
      </div>
    </div>
  )
}
export default ExploreBox
const ImageWrapper = styled(GatsbyImage)`
  width: ${props => props.width}px;
  height: ${props => 1.4 * props.width}px;
  margin-top: 20px;
  background-size: auto ${props => 1.5 * props.width}px;
  border-radius: 10px;
  .explore__thumb__bg {
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 10px;
    background-size: contain;
  }
  @media (max-width: 767px) {
    height: ${props => 1.2 * props.width}px;
  }
`
