import React from "react"
import { PortableText } from "@portabletext/react"
const ExploreText = ({ title, description }) => {
  return (
    <div
      className="explore_blk_text"
      data-aos="fade-up"
      data-aos-delay="50"
      data-aos-duration="1000"
    >
      <h3>{title}</h3>
      <PortableText value={description} />
    </div>
  )
}
export default ExploreText
