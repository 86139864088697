import * as React from "react"
import BookingOnline from "../../components/BookingOnline"
import ExploreBox from "../../components/ExploreBox"
import ExploreText from "../../components/ExploreText"
import Layout from "../../components/Layout"
import Aos from "aos"
import BackToTop from "../../components/BackToTop"
import { graphql } from "gatsby"
import Seo from "../../components/Seo"

const DirectionAndPress = ({ data }) => {
  React.useEffect(() => {
    Aos.init()
  }, [])

  const { title, _rawDescription, pressItems, pressContact } =
    data?.sanityPages?.pressPage
  return (
    <>
      <Seo
        title={"Press Page"}
        description={"Description to be added later for this press page."}
      />
      <Layout>
        <div className="header_bottom special-_5">
          <div className="container">
            <BookingOnline />
          </div>
          <BackToTop />
        </div>
        <div className="explore__section">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="explore_block">
                  <ExploreText
                    title={title}
                    description={_rawDescription}
                    index={0}
                  />
                  <div className="row">
                    {pressItems.map((item, index) => (
                      <div className="col-lg-4 col-md-6">
                        <ExploreBox
                          key={index}
                          press
                          image={item?.thumbnail?.asset?.gatsbyImageData}
                          title={item.title}
                          button={{
                            title: "Read",
                            url: item.link,
                          }}
                          index={index}
                          icon={
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="13"
                              height="8"
                              viewBox="0 0 13 8"
                              fill="none"
                            >
                              <path
                                d="M0.5 3.5C0.223858 3.5 0 3.72386 0 4C0 4.27614 0.223858 4.5 0.5 4.5V3.5ZM12.3536 4.35355C12.5488 4.15829 12.5488 3.84171 12.3536 3.64645L9.17157 0.464466C8.97631 0.269204 8.65973 0.269204 8.46447 0.464466C8.2692 0.659728 8.2692 0.976311 8.46447 1.17157L11.2929 4L8.46447 6.82843C8.2692 7.02369 8.2692 7.34027 8.46447 7.53553C8.65973 7.7308 8.97631 7.7308 9.17157 7.53553L12.3536 4.35355ZM0.5 4.5H12V3.5H0.5V4.5Z"
                                fill="#002643"
                              />
                            </svg>
                          }
                          pageClass={"press__thumb__bg"}
                        />
                      </div>
                    ))}
                  </div>
                </div>
                <div
                  className="explore_block"
                  data-aos="fade-up"
                  data-aos-delay="400"
                  data-aos-duration="1700"
                >
                  <div className="explore_blk_text trt">
                    <h3>{pressContact.title}</h3>
                  </div>
                  <div className="press_scott">
                    <h4>{pressContact.name}</h4>
                    <span>{pressContact.designation}</span>
                    <a href="#">{pressContact.email}</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}
export default DirectionAndPress
export const pressData = graphql`
  query ($id: String!) {
    sanityPages(id: { eq: $id }) {
      title
      template
      handle {
        current
      }
      pressPage {
        title
        _rawDescription
        pressItems {
          title
          thumbnail {
            asset {
              gatsbyImageData
            }
          }
          link
        }
        pressContact {
          title
          name
          designation
          email
        }
      }
    }
  }
`
